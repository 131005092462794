import React from 'react';

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FooterStyle12 from '../Components/Footers/FooterStyle12';
import { MobileMenu } from '../Components/Header/Header';
import InViewPort from '../Components/InViewPort';
import SocialIcons from '../Components/SocialIcon/SocialIcons';

// Components
import { Header, HeaderNav, Menu } from '../Components/Header/Header';

import LogoNivomi from '../Assets/img/logo-nivomi.svg';

const SocialIconsData = [
	{
		color: '#4867ae',
		link: 'https://github.com/VolkerLieber/',
		icon: 'fa-brands fa-github',
	},
	{
		color: '#4867ae',
		link: 'https://www.linkedin.com/in/volkerlieber/',
		icon: 'fa-brands fa-linkedin',
	},
];

const Imprint = (props) => {
	return (
		<div style={props.style}>
			<div className="bg-white">
				{/* Header Start */}
				<Header topSpace={{ md: true }} type="reverse-scroll">
					<HeaderNav
						fluid="fluid"
						theme="light"
						expand="lg"
						className="digital-agency-header py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
						containerClass="md:pr-0 gap-x-[15px]"
					>
						<Col lg={2} sm={6} xs={'auto'} className="mr-auto ps-0">
							<Link aria-label="header logo" className="flex items-center" to="/">
								<Navbar.Brand className="inline-block p-0 m-0">
									<img
										className="default-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="alt-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="mobile-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
								</Navbar.Brand>
							</Link>
						</Col>
						<Menu className="justify-center col-auto col-lg-8 md:hidden" {...props} />
						<MobileMenu
							className="order-last d-lg-none self-stretch px-[15px]"
							type="full"
							{...props}
						/>
						<Col lg={2} xs={'auto'} className="justify-end pe-0 flex items-center">
							<SocialIcons
								theme="social-icon-style-01 block text-center"
								iconColor="dark"
								size="xs"
								data={SocialIconsData}
							/>
						</Col>
					</HeaderNav>
				</Header>
				{/* Header End */}

				{/* Section Start */}
				<InViewPort>
					<section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
						<Container>
							<Row className="items-center justify-center">
								<Col lg={9} md={12} className="col-12">
									<h5 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Impressum
									</h5>

									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Angaben gem&auml;&szlig; &sect; 5 TMG
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Nivomi UG (haftungsbeschränkt)
										<br />
										Coburger Stra&szlig;e 7<br />
										96472 R&ouml;dental
									</p>

									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Handelsregister: 6214
										<br />
										Registergericht: Coburg
									</p>

									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										<strong>Vertreten durch:</strong>
										<br />
										Volker Lieber
									</p>

									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Kontakt
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Telefon: +49 176 568 189 05
										<br />
										E-Mail: info@nivomi.com
									</p>

									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Umsatzsteuer-ID
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
										Umsatzsteuergesetz:
										<br />
										DE319557597
									</p>

									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										EU-Streitschlichtung
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die Europ&auml;ische Kommission stellt eine Plattform zur
										Online-Streitbeilegung (OS) bereit:{' '}
										<a
											href="https://ec.europa.eu/consumers/odr/"
											target="_blank"
											rel="noopener noreferrer"
										>
											https://ec.europa.eu/consumers/odr/
										</a>
										.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
									</p>

									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
										vor einer Verbraucherschlichtungsstelle teilzunehmen.
									</p>

									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
									</p>
								</Col>
							</Row>
						</Container>
					</section>
				</InViewPort>
				{/* Section End */}
			</div>

			{/* Footer Start */}
			<FooterStyle12
				className="footer-digitalagency text-white bg-black"
				theme="light"
				logo={LogoNivomi}
			/>
			{/* Footer End */}
		</div>
	);
};

export default Imprint;
