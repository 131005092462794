import React from 'react';

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FooterStyle12 from '../Components/Footers/FooterStyle12';
import { MobileMenu } from '../Components/Header/Header';
import InViewPort from '../Components/InViewPort';
import SocialIcons from '../Components/SocialIcon/SocialIcons';

// Components
import { Header, HeaderNav, Menu } from '../Components/Header/Header';

import LogoNivomi from '../Assets/img/logo-nivomi.svg';

const SocialIconsData = [
	{
		color: '#4867ae',
		link: 'https://github.com/VolkerLieber/',
		icon: 'fa-brands fa-github',
	},
	{
		color: '#4867ae',
		link: 'https://www.linkedin.com/in/volkerlieber/',
		icon: 'fa-brands fa-linkedin',
	},
];

const Privacy = (props) => {
	return (
		<div style={props.style}>
			<div className="bg-white">
				{/* Header Start */}
				<Header topSpace={{ md: true }} type="reverse-scroll">
					<HeaderNav
						fluid="fluid"
						theme="light"
						expand="lg"
						className="digital-agency-header py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
						containerClass="md:pr-0 gap-x-[15px]"
					>
						<Col lg={2} sm={6} xs={'auto'} className="mr-auto ps-0">
							<Link aria-label="header logo" className="flex items-center" to="/">
								<Navbar.Brand className="inline-block p-0 m-0">
									<img
										className="default-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="alt-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="mobile-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
								</Navbar.Brand>
							</Link>
						</Col>
						<Menu className="justify-center col-auto col-lg-8 md:hidden" {...props} />
						<MobileMenu
							className="order-last d-lg-none self-stretch px-[15px]"
							type="full"
							{...props}
						/>
						<Col lg={2} xs={'auto'} className="justify-end pe-0 flex items-center">
							<SocialIcons
								theme="social-icon-style-01 block text-center"
								iconColor="dark"
								size="xs"
								data={SocialIconsData}
							/>
						</Col>
					</HeaderNav>
				</Header>
				{/* Header End */}

				{/* Section Start */}
				<InViewPort>
					<section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
						<Container>
							<Row className="items-center justify-center">
								<Col lg={9} md={12} className="col-12">
									<h5 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Datenschutz&shy;erkl&auml;rung
									</h5>
									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										1. Datenschutz auf einen Blick
									</h6>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Allgemeine Hinweise
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die folgenden Hinweise geben einen einfachen &Uuml;berblick
										dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
										diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
										denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
										Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
										unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Datenerfassung auf dieser Website
									</h7>{' '}
									<h8 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
									</h8>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die Datenverarbeitung auf dieser Website erfolgt durch den
										Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
										&bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
										Datenschutzerkl&auml;rung entnehmen.
									</p>{' '}
									<h8 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Wie erfassen wir Ihre Daten?
									</h8>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
										mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
										in ein Kontaktformular eingeben.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
										Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
										technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
										Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
										automatisch, sobald Sie diese Website betreten.
									</p>{' '}
									<h8 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Wof&uuml;r nutzen wir Ihre Daten?
									</h8>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
										der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
										Analyse Ihres Nutzerverhaltens verwendet werden.
									</p>{' '}
									<h8 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
									</h8>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
										Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
										personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
										Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
										Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
										k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
										widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
										Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
										personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
										Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
										sich jederzeit an uns wenden.
									</p>
									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										2. Hosting
									</h6>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Externes Hosting
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Diese Website wird extern gehostet. Die personenbezogenen Daten, die
										auf dieser Website erfasst werden, werden auf den Servern des Hosters
										/ der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um
										IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
										Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
										Daten, die &uuml;ber eine Website generiert werden, handeln.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung
										gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
										Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
										effizienten Bereitstellung unseres Online-Angebots durch einen
										professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
										entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
										ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
										&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
										Cookies oder den Zugriff auf Informationen im Endger&auml;t des
										Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
										Die Einwilligung ist jederzeit widerrufbar.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
										wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
										und unsere Weisungen in Bezug auf diese Daten befolgen.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wir setzen folgende(n) Hoster ein:
									</p>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Contabo GmbH
										<br />
										Aschauer Stra&szlig;e 32a
										<br />
										81549 M&uuml;nchen
										<br />
										Deutschland
									</p>
									<h8 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Auftragsverarbeitung
									</h8>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
										Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
										sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
										gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
										Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
										DSGVO verarbeitet.
									</p>
									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										3. Allgemeine Hinweise und Pflicht&shy;informationen
									</h6>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Datenschutz
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
										Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
										vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
										sowie dieser Datenschutzerkl&auml;rung.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
										Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
										pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
										Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
										wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
										Zweck das geschieht.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
										(z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
										aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
										durch Dritte ist nicht m&ouml;glich.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Hinweis zur verantwortlichen Stelle
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
										Website ist:
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Nivomi UG (haftungsbeschr&auml;nkt)
										<br />
										Coburger Stra&szlig;e 7<br />
										96472 R&ouml;dental
									</p>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Telefon: +49 176 568 189 05
										<br />
										E-Mail: info@nivomi.com
									</p>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
										Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
										Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
										E-Mail-Adressen o. &Auml;.) entscheidet.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Speicherdauer
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
										Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
										bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
										Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
										Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
										gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
										Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
										haben (z.&nbsp;B. steuer- oder handelsrechtliche
										Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
										L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf
										dieser Website
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
										wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
										a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
										Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
										einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
										personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
										au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern
										Sie in die Speicherung von Cookies oder in den Zugriff auf
										Informationen in Ihr Endger&auml;t (z.&nbsp;B. via
										Device-Fingerprinting) eingewilligt haben, erfolgt die
										Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1
										TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
										Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher
										Ma&szlig;nahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage
										des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
										Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung
										erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
										Datenverarbeitung kann ferner auf Grundlage unseres berechtigten
										Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die
										jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den
										folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Empfänger von personenbezogenen Daten
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit
										verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine
										&Uuml;bermittlung von personenbezogenen Daten an diese externen
										Stellen erforderlich. Wir geben personenbezogene Daten nur dann an
										externe Stellen weiter, wenn dies im Rahmen einer
										Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich hierzu
										verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an
										Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6
										Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige
										Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
										Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden
										nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber
										Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung
										wird ein Vertrag &uuml;ber gemeinsame Verarbeitung geschlossen.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Widerruf Ihrer Einwilligung zur Datenverarbeitung
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
										ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
										bereits erteilte Einwilligung jederzeit widerrufen. Die
										Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
										Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
										sowie gegen Direktwerbung (Art. 21 DSGVO)
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
										F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
										SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
										IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
										F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
										JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
										ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
										EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
										MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
										SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
										DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
										VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
										VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
										BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
										VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
										DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
										SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
										WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
										MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
										ABS. 2 DSGVO).
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Beschwerde&shy;recht bei der zust&auml;ndigen
										Aufsichts&shy;beh&ouml;rde
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
										Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
										insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
										ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
										Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
										anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Recht auf Daten&shy;&uuml;bertrag&shy;barkeit
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
										oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
										sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
										Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
										&Uuml;bertragung der Daten an einen anderen Verantwortlichen
										verlangen, erfolgt dies nur, soweit es technisch machbar ist.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Auskunft, Berichtigung und L&ouml;schung
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
										das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
										personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
										Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
										L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
										personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Recht auf Einschr&auml;nkung der Verarbeitung
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
										personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
										jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
										Verarbeitung besteht in folgenden F&auml;llen:
									</p>{' '}
									<ul>
										{' '}
										<li>
											Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
											personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
											Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
											Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
											Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
										</li>{' '}
										<li>
											Wenn die Verarbeitung Ihrer personenbezogenen Daten
											unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
											L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
										</li>{' '}
										<li>
											Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
											sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
											Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
											L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
											personenbezogenen Daten zu verlangen.
										</li>{' '}
										<li>
											Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
											muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
											vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
											&uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
											Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
										</li>{' '}
									</ul>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
										eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
										Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
										Geltendmachung, Aus&uuml;bung oder Verteidigung von
										Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
										nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
										wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
										oder eines Mitgliedstaats verarbeitet werden.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										SSL- bzw. TLS-Verschl&uuml;sselung
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
										&Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
										oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
										bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
										erkennen Sie daran, dass die Adresszeile des Browsers von
										&bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
										Schloss-Symbol in Ihrer Browserzeile.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
										die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
										mitgelesen werden.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Widerspruch gegen Werbe-E-Mails
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
										Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
										angeforderter Werbung und Informationsmaterialien wird hiermit
										widersprochen. Die Betreiber der Seiten behalten sich
										ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
										Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
									</p>
									<h6 className="font-serif text-darkgray font-medium -tracking-[1px]">
										4. Datenerfassung auf dieser Website
									</h6>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Server-Log-Dateien
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Der Provider der Seiten erhebt und speichert automatisch Informationen
										in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
										&uuml;bermittelt. Dies sind:
									</p>{' '}
									<ul>
										{' '}
										<li>Browsertyp und Browserversion</li>{' '}
										<li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
										<li>Hostname des zugreifenden Rechners</li>{' '}
										<li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
									</ul>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
										nicht vorgenommen.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
										lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
										der technisch fehlerfreien Darstellung und der Optimierung seiner
										Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
										werden.
									</p>
									<h7 className="font-serif text-darkgray font-medium -tracking-[1px]">
										Anfrage per E-Mail, Telefon oder Telefax
									</h7>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
										Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
										(Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
										gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
										Einwilligung weiter.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
										lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
										Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
										vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
										&uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
										berechtigten Interesse an der effektiven Bearbeitung der an uns
										gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
										Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
										wurde; die Einwilligung ist jederzeit widerrufbar.
									</p>{' '}
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
										verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
										Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
										Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
										Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
										&ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
										unber&uuml;hrt.
									</p>
									<p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">
										Quelle:{' '}
										<a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
									</p>
								</Col>
							</Row>
						</Container>
					</section>
				</InViewPort>
				{/* Section End */}
			</div>

			{/* Footer Start */}
			<FooterStyle12
				className="footer-digitalagency text-white bg-black"
				theme="light"
				logo={LogoNivomi}
			/>
			{/* Footer End */}
		</div>
	);
};

export default Privacy;
