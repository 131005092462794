const HeaderData = [
	{
		title: 'Home',
		link: '/',
	},
	{
		title: 'Services',
		id: '/#services',
	},
	{ title: 'Contact us', link: 'mailto:info@nivomi.com' },
	{ title: 'Privacy Policy', link: '/privacy' },
	{ title: 'Imprint', link: '/imprint' },
];

export default HeaderData;
