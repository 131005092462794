import React, { useEffect, useRef, useState } from 'react';

// Libraries
import { m } from 'framer-motion';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

// Components
import Header, {
	HeaderNav,
	Menu,
	MobileMenu,
} from '../../Components/Header/Header';
import InteractiveBanners01 from '../../Components/InteractiveBanners/InteractiveBanners01';
import SocialIcons from '../../Components/SocialIcon/SocialIcons';
import { fadeIn } from '../../Functions/GlobalAnimations';

import FooterStyle12 from '../../Components/Footers/FooterStyle12';

// Data
import { InteractiveBannersData01 } from '../../Components/InteractiveBanners/InteractiveBannersData';

import InViewPort from '../../Components/InViewPort';

import BackgroundVideo from '../../Assets/mp4/background.mp4';

import LogoNivomi from '../../Assets/img/logo-nivomi.svg';

import { useScramble } from 'use-scramble';

const SocialIconsData = [
	{
		color: '#4867ae',
		link: 'https://github.com/VolkerLieber/',
		icon: 'fa-brands fa-github',
	},
	{
		color: '#4867ae',
		link: 'https://www.linkedin.com/in/volkerlieber/',
		icon: 'fa-brands fa-linkedin',
	},
];

const DigitalagencyPage = (props) => {
	const [scrambleTexts, setScrambleTexts] = useState(["Software", "10"]);
	const { ref: scrambleTitleRef } = useScramble({ 
		text: scrambleTexts[0],  scramble: 10, overdrive: false
	  });
	  const { ref: scrambleDetailRef } = useScramble({ 
		text: scrambleTexts[1],  scramble: 10, overdrive: false
	  });
	  
	const videoRef = useRef();

	useEffect(() => {
		videoRef.current.playbackRate = 0.4;
	}, []);


	useEffect(()=>{
		const timeout = setInterval(() => {
			setScrambleTexts((old) => {
				switch (old[0]) {
					case "Software":
						return ["Reverse Engineering", "8"]
					case "Reverse Engineering":
						return ["Penetration Testing", "6"]
					case "Penetration Testing":
						return ["Training", "3"]
					default:
						return ["Software", "10"]
				}
				})
		}, 5000);

		return () => {
			clearInterval(timeout);
		}
	},[]);

	return (
		<div style={props.style}>
			<div className="bg-white">
				{/* Header Start */}
				<Header topSpace={{ md: true }} type="reverse-scroll">
					<HeaderNav
						fluid="fluid"
						theme="dark"
						expand="lg"
						className="digital-agency-header py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
						containerClass="md:pr-0 gap-x-[15px]"
					>
						<Col lg={2} sm={6} xs={'auto'} className="mr-auto ps-0">
							<Link aria-label="header logo" className="flex items-center" to="/">
								<Navbar.Brand className="inline-block p-0 m-0">
									<img
										className="default-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="alt-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
									<img
										className="mobile-logo"
										width="111"
										height="36"
										loading="lazy"
										src={LogoNivomi}
										data-rjs={LogoNivomi}
										alt="logo"
									/>
								</Navbar.Brand>
							</Link>
						</Col>
						<Menu className="justify-center col-auto col-lg-8 md:hidden" {...props} />
						<MobileMenu
							className="order-last d-lg-none self-stretch px-[15px]"
							type="full"
							{...props}
						/>
						<Col lg={2} xs={'auto'} className="justify-end pe-0 flex items-center">
							<SocialIcons
								theme="social-icon-style-01 block text-center"
								iconColor="light"
								size="xs"
								data={SocialIconsData}
							/>
						</Col>
					</HeaderNav>
				</Header>
				{/* Header End */}

				{/* Parallax Scrolling Start */}
				<div className="full-screen md:h-[600px] sm:h-[350px] md:flex md:items-center overflow-hidden relative bg-black">
					<Parallax
						className="lg-no-parallax bg-cover absolute top-[0px] left-0 md:-top-[30px] w-full h-[100vh] sm:h-[70vh]"
						translateY={[-40, 40]}
						style={{
							filter: 'blur(5px)',
							// backgroundImage: `url(https://v5.cdnpk.net/videvo_files/video/premium/partners0237/large_watermarked/BB_3bd322b4-d0b3-4625-9582-be2cdd3119dd_FPpreview.mp4)`,
						}}
					>
						<video
							autoPlay
							loop
							muted
							style={{ height: '100%', width: '100%', objectFit: 'cover' }}
							ref={videoRef}
						>
							<source src={BackgroundVideo} type="video/mp4"></source>
						</video>
					</Parallax>
					<div className="absolute top-0 left-0 h-full w-full opacity-30 bg-gradient-to-b from-[transparent] to-[#1f232c]"></div>
					<Container className="relative md:h-full">
						<Row className="h-[100vh] md:h-full">
							<Col className="flex flex-col">
								<h1  className="font-serif font-semibold text-white text-[130px] leading-[120px] w-[50%] mt-auto mb-0 tracking-[-5px] lg:text-[110px] lg:leading-[100px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px] sm:-tracking-[.5px] xs:w-[70%] xs:text-[60px] xs:leading-[65px] break-normal">
									<p ref={scrambleTitleRef} className='text-[#4867AE]'>Software</p> done right<span className="text-[#4867AE]">.</span>
								</h1>
								<div className="flex flex-row items-center mt-auto mb-[7.5rem]">
									<span className="bg-white w-[50%] h-[1px] mr-36 md:w-[40%] sm:mr-8 sm:w-[50px] xs:mr-8 opacity-[0.5]"></span>
									<span className="font-serif text-lg text-white xs:text-xmd">
										<span className='opacity-[0.5]'>Over </span><span ref={scrambleDetailRef} className='text-[#4867AE] opacity-[1.0]'>10</span><span className='opacity-[0.5]'> years of experience</span>
									</span>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				{/* Parallax Scrolling End */}

				{/* Section Start */}
				<section
					id="services"
					className="py-[160px] overflow-hidden relative lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
				>
					<Container>
						<Row className="items-center justify-center">
							<Col xl={5} md={6} xs={11} className="sm:mb-[30px]">
								<m.h2
									{...{ ...fadeIn, transition: { delay: 0.2 } }}
									className="heading-4 font-serif font-semibold text-darkgray mb-0"
								>
									We <span className="text-[#4867AE]">create</span>,<br></br>
									<span className="text-[#4867AE]">teach</span> and{' '}
									<span className="text-[#4867AE]">ensure</span> the future of digital
									services
								</m.h2>
							</Col>
							<Col xl={{ span: 5, offset: 1 }} md={6} xs={11}>
								<m.p
									{...{ ...fadeIn, transition: { delay: 0.3 } }}
									className="text-lg mb-[25px] leading-[38px] md:text-xmd"
								>
									With over a decade of experience in the tech industry, we are your
									trusted partner for software development, training, and IT security
									services. We are specialized in delivering innovative solutions to
									businesses of all sizes.
								</m.p>
								{/* <m.div {...{ ...fadeIn, transition: { delay: 0.4 } }}>
									<Buttons
										ariaLabel="button link for our services page"
										to="/home-digital-agency#services"
										className="font-medium font-serif uppercase btn-link after:h-[2px] md:text-md md:mb-[15px] after:bg-darkgray hover:text-darkgray"
										size="xl"
										color="#232323"
										title="Explore services"
									/>
								</m.div> */}
							</Col>
						</Row>
					</Container>
				</section>
				{/* Section End */}

				{/* Lazy Load HTML */}
				<InViewPort>
					{/* Section Start */}
					<section className="overflow-hidden relative">
						<InteractiveBanners01
							grid="row-cols-1"
							data={InteractiveBannersData01}
							animation={fadeIn}
							style={{ '--base-color': '#4867AE' }}
						/>
					</section>
					{/* Section End */}

					{/* Section Start */}
					<section className="py-[160px] overflow-hidden relative lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
						<Container>
							<div className="row items-center">
								<m.div
									className="col-12 col-lg-3 col-md-4 col-sm-6 xs:mb-[40px] xs:px-[40px]"
									{...{ ...fadeIn, transition: { delay: 0.2 } }}
								>
									<span className="font-serif font-semibold text-xlg text-darkgray tracking-[-.5px] block mb-[15px] lg:text-lg">
										Different thinking
									</span>
									<p className="text-lg leading-[38px] xs:w-[90%] md:text-xmd">
										We are creative minds with a penchant for unconventional thinking,
										driving innovation in every project we undertake.
									</p>
								</m.div>
								<m.div
									{...{ ...fadeIn, transition: { delay: 0.4 } }}
									className="xs:px-[40px] col-lg-3 offset-xl-1 col-md-4 col-sm-6"
								>
									<span className="font-serif font-semibold text-xlg text-darkgray -tracking-[.5px] block mb-[15px] lg:text-lg">
										Digital talents
									</span>
									<p className="text-lg leading-[38px] xs:w-[90%] md:text-xmd">
										We are digital talents with a favour for pushing boundaries, bringing
										fresh perspectives to the digital landscape with each endeavor.
									</p>
								</m.div>
								<m.div
									className="col-md-4 offset-xl-1 absolute right-0 w-auto"
									{...{ ...fadeIn, transition: { delay: 0.6 } }}
								>
									<div>
										<span className="font-serif font-semibold text-darkgray opacity-[.1] text-[300px] tracking-[-10px] leading-[100px] lg:text-[200px] md:text-[150px] sm:hidden break-normal">
											dev
										</span>
									</div>
								</m.div>
							</div>
						</Container>
					</section>
					{/* Section End */}
				</InViewPort>
			</div>

			{/* Footer Start */}
			<FooterStyle12
				className="footer-digitalagency text-white bg-black"
				theme="light"
				logo={LogoNivomi}
			/>
			{/* Footer End */}
		</div>
	);
};

export default DigitalagencyPage;
