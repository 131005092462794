const FooterData = [
	{
		title: 'company',
		submenu: [
			{
				title: 'Contact us',
				link: 'mailto:info@nivomi.com',
			},
		],
	},
	{
		title: 'customer',
		submenu: [
			{
				title: 'Guiding Your Success',
				link: '/blogs/blog-post-layout-01',
			},
			{
				title: 'Crafting Digital Solutions',
				link: '/blogs/blog-post-layout-01',
			},
			{
				title: 'Protecting Your Digital Assets',
				link: '/blogs/blog-post-layout-03',
			},
			{
				title: 'Empowering Your Team',
				link: '/blogs/blog-post-layout-02',
			},
		],
	},
	{
		title: 'Additional',
		submenu: [
			{
				title: 'Our story',
				link: '/page/our-story',
			},
			{
				title: 'Who we are',
				link: '/page/who-we-are',
			},
			{
				title: 'Our process',
				link: '/page/our-process',
			},
			{
				title: 'Latest news',
				link: '/page/latest-news',
			},
		],
	},
	{
		title: 'Services',
		submenu: [
			{
				title: 'Consulting',
				id: '/#services',
			},
			{
				title: 'Software Development',
				id: '/#services',
			},
			{
				title: 'IT Security',
				id: '/#services',
			},
			{
				title: 'Training',
				id: '/#services',
			},
		],
	},
	{
		title: 'Resources',
		submenu: [
			{
				title: 'Theme guide',
				link: '/page/faq-s',
			},
			{
				title: 'Support desk',
				link: '/page/faq-s',
			},
			{
				title: 'What we offer',
				link: '/page/what-we-offer',
			},
			{
				title: 'Company history',
				link: '/page/our-story',
			},
		],
	},
	{
		title: 'Categories',
		submenu: [
			{
				title: 'For men',
				link: '/shop/shop-wide',
			},
			{
				title: 'For woman',
				link: '/shop/shop-wide',
			},
			{
				title: 'Accessories',
				link: '/shop/shop-wide',
			},
			{
				title: 'Collections',
				link: '/shop/shop-wide',
			},
		],
	},
	{
		title: 'Adavantages',
		submenu: [
			{
				title: 'Free delivery',
				link: '/page/faq-s',
			},
			{
				title: '100 days refund',
				link: '/page/faq-s',
			},
			{
				title: 'Multiple payments',
				link: '/page/faq-s',
			},
			{
				title: 'Sustainable',
				link: '/page/faq-s',
			},
		],
	},
];

export default FooterData;
